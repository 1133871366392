import React, { Component } from "react";
import styles from "./hero.module.css";

export default class Hero extends Component {
  render() {
    return (
      <div
        className={styles.hero}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${this.props.heroImage})`,
          maxHeight: this.props.maxHeight || "inherit",
          height: this.props.maxHeight,
          minHeight: this.props.maxHeight ? "initial" : this.props.minHeight,
          justifyContent: this.props.justifyContent || "space-between",
          ...this.props.style
        }}
      >
        {this.props.children}
      </div>
    );
  }
}
