import React from "react";
import { Helmet } from "react-helmet";
import { FaFrown } from "react-icons/fa";
import Hero from "../components/hero";
import Layout from "../components/layout";
import SEO from "../components/seo";
import heroImage2 from "../images/tlo.jpg";
import styles from "./baza-wiedzy.module.css";

const Ups = () => {
  return (
    <Layout>

      <Hero
        style={{ backgroundImage: `url(${heroImage2})` }}
        maxHeight="280px"
        justifyContent="center"
      >
      <h1>Ups...</h1>
        <h2>Coś poszło nie tak</h2>
      </Hero>

      <SEO
        title="Informacja o chwilowych problemach"
      />
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <center>
        <br />
        <br />
        <FaFrown size="120px" color="#3498db" />
        <br />
        <br />
        <p>
          Coś poszło nie tak... Najwyraźniej doświadczamy chwilowych problemów
          technicznych. <br />
          Serdecznie przepraszamy i prosimy o bezpośredni kontakt telefoniczny
          lub mailowy.
        </p>
        <p>
          <a
            className={styles.phoneNum}
            href="tel:+48506504540"
            title="+48 506 504 540"
          >
            +48 506 504 540
          </a>
        </p>
        <p>
          <a
            className={styles.phoneNum}
            href="mailto:kontakt@questy.pl"
            title="kontakt@questy.pl"
          >
            kontakt@questy.pl
          </a>
        </p>
      </center>
    </Layout>
  );
};

export default Ups;
